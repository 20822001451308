import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsConstants } from 'projects/is-core/src/lib/settings/settings.service';
import { UtilsInitializerService } from 'projects/is-core/src/lib/utils-initializer/utils-initializer.service';
import { IsLoginService } from './is-login.service';
import { CookieService } from 'ngx-cookie';
import Cookies from 'js-cookie';
import { Subscription } from 'rxjs';

@Component({
  selector: 'is-login',
  templateUrl: './is-login.component.html',
  styleUrls: ['./is-login.component.scss']
})
export class IsLoginComponent implements OnInit, OnDestroy {

  @Input() showApps: boolean = true;

  public loginForm: FormGroup = new FormGroup({
    username: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required])
  });
  public passwordSub$: Subscription;

  public typingPassword: boolean = false;
  public showPassword: boolean = false;

  // public loginForm: UntypedFormGroup;
  public showSpinner = false;
  public infoAuth: any = {
    'enabled': false,
    'button_text': '',
    'button_url': ''
  };

  private get AUTH_URL(): string {
    return `${SettingsConstants.BACKENDURL}/rest-auth/login/`;
  }

  constructor(private _http: HttpClient,
    private _router: Router,
    private _isLoginService: IsLoginService,
    private _utilsInitializerService: UtilsInitializerService,
    private _toastr: ToastrService,
    private _cookieService: CookieService,
    private _cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    // check typing password for show view button
    this.passwordSub$ = this.loginForm.get('password').valueChanges.subscribe((value) => {
      if (value && value !== '') {
        this.typingPassword = true;
      } else {
        this.typingPassword = false;
      }
    });

    // remove cookies about session and login
    this._cookieService.remove('sessionid');
    this._cookieService.remove('token');
    this._cookieService.remove('csrftoken');
    if (this._cookieService.get('dataXp_time')) {
      this._cookieService.remove('dataXp_time');
    }

    if (localStorage.getItem('token') != null) {
      this._trySkipAuthenticationByStorage();
    } else if (this._cookieService.get('token') != null) {
      this._trySkipAuthenticationByCookie();
    } else {
      this._checkForExternalAuth();
    }

    setTimeout(() => {
      // Forza l'aggiornamento dello stato e la validità del form
      this.loginForm.updateValueAndValidity();
    }, 800);
  }

  ngOnDestroy(): void {
    if (this.passwordSub$ && !this.passwordSub$?.closed) {
      this.passwordSub$?.unsubscribe();
    }
  }

  private _trySkipAuthenticationByStorage(): void {
    const headers = new HttpHeaders();
    headers.append('Authorization', `token ${localStorage.getItem('token')}`);
    this._http.get(`${SettingsConstants.BACKENDURL}/rest-auth/user/`, { headers }).subscribe({
      next: (data: any) => {
        this._router.navigate(['']);
      },
      error: () => { },
      complete: () => { }
    });
  }

  private _trySkipAuthenticationByCookie(): void {
    const headers = new HttpHeaders();
    headers.append('Authorization', `token ${this._cookieService.get('token')}`);
    this._http.get(`${SettingsConstants.BACKENDURL}/rest-auth/user/`, { headers }).subscribe({
      next: (data: any) => {
        this._router.navigate(['']);
      },
      error: () => { },
      complete: () => { }
    });
  }

  private _checkForExternalAuth(): void {
    // check foe external authentication. Call a non authenticaed BE API
    const response: any = this._http.get(`${SettingsConstants.BACKENDURL}/inspiring/isauth/external_auth_get_configuration/`, {
      headers: this.getHeaders()
    }).subscribe(
      (data) => {
        this.infoAuth = data;
        if (response || !response?.closed) {
          response.unsubscribe();
        }
      },
      (error) => {
        if (response || !response?.closed) {
          response.unsubscribe();
        }
      });
  }

  public getHeaders() {
    const token = Cookies.get('token');
    if (token) {
      return {
        'Authorization': `token ${token}`
      };
    }
    return null;
  }


  public changeShowPassword(): void {
    this.showPassword = !this.showPassword;
    this._cd.detectChanges();
  }

  public submit(): void {
    this.showSpinner = true;
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;
    this._http.post(this.AUTH_URL, { username, password }).subscribe({
      next: async (data: any) => {
        if (data?.token) {
          this._isLoginService.setToken(data.token);
        } else if (data?.key) {
          this._isLoginService.setToken(data.key);
        } else if (data?.access) {
          this._isLoginService.setToken(data.access);
        }
        const redirect = this._isLoginService.redirectUrl ?? '/';
        if (SettingsConstants.LOGGEDUSER?.username) {
          SettingsConstants.LOGGEDUSER.username = username;
        }

        await this._utilsInitializerService.init();

        this._router.navigate([redirect]);
        setTimeout(() => {
          this.showSpinner = false;
        }, 1000);
      },
      error: () => {
        this.showSpinner = false;
        this._toastr.error('User name or password is incorrect');
      },
      complete: () => { }
    });
  }

  public navigateExternalAuth(): void {
    const url: any = this.infoAuth.button_url;
    window.open(url, '_self');
  }
}

export interface IsLoginResponse {
  key: string;
}
