export const APP_ICONS = [
  '3D_model',
  'act',
  'activity_unassigned',
  'AD_light_disabled',
  'AD_light_enabled',
  'add_asset',
  'add',
  'add_alarm_criterion',
  'add_alert',
  'add_as_homepage',
  'add_columns',
  'add_dataset',
  'add_enpi',
  'add_folder',
  'add_image',
  'add_link',
  'add_note',
  'add_photo',
  'add_rows',
  'add_user',
  'agenda',
  'ai',
  'alarm',
  'alarm_full',
  'alarm_clock',
  'alarm_off',
  'alarm_on',
  'alarm_on_full',
  'alert',
  'attached',
  'ameliorative_action_sidenav',
  'ampolla',
  'analytics',
  'and',
  'anomaly',
  'apps',
  'archive',
  'area',
  'arrow2_down',
  'arrow2_left',
  'arrow2_right',
  'arrow2_up',
  'arrow3_down',
  'arrow3_left',
  'arrow3_right',
  'arrow3_up',
  'arrow_circle_down',
  'arrow_dots',
  'arrow_down',
  'arrow_down_circle',
  'arrow_go_back',
  'arrow_left',
  'arrow_left_right',
  'arrow_right',
  'arrow_up',
  'arrow_up_circle',
  'artifical-intelligence',
  'artificial-intelligence',
  'asset',
  'asset_icon',
  'asset_management',
  'assistance',
  'associate_site',
  'attach',
  'attach2',
  'attach_rotate',
  'auc_roc_curve',
  'autocorrelation',
  'axes',
  'bad',
  'bag',
  'bar_chart',
  'benchmark',
  'benchmark2',
  'binarizer',
  'box_plot',
  'bracket_left',
  'bracket_right',
  'builder',
  'calendar',
  'calendar_note',
  'cancel',
  'card',
  'card_carousel_loading',
  'cart',
  'Categoric_Box_plot',
  'Categoric_histogram',
  'Categories_plot',
  'cefla_logo',
  'change_request',
  'chart_of_percentage_deviations',
  'check',
  'check_circle',
  'checkbox',
  'chevron_right',
  'chiave_inglese',
  'Chiudi',
  'circle_close_full',
  'Clessidra',
  'close',
  'close_circle',
  'coded_element',
  'colors',
  'columns',
  'comment',
  'comments',
  'compare_sites',
  'compress',
  'confusion_matrix',
  'Constraint',
  'contractor',
  'copy',
  'crea_dataset',
  'create_first_group',
  'create_first_report',
  'create_dataset',
  'create_test_dataset',
  'create_time_frame',
  'Cronologia',
  'cusum',
  'cusum2',
  'cusum_tabular',
  'Dashboard&Report',
  'dashboard',
  'dashborad_report',
  'Data Experience',
  'data_experience',
  'data_experience2',
  'data_visualization',
  'dataset',
  'dataset_copy',
  'date_range',
  'Deadline_setting',
  'Deviations_chart',
  'device',
  'device_profile_picture',
  'diary',
  'dimensionality_reduction',
  'discretization',
  'divide',
  'Divieto',
  'Do',
  'document',
  'document2',
  'document3',
  'do_not_disturb',
  'double_arrow_left',
  'double_arrow_right',
  'download',
  'drag_drop',
  'drag_handle',
  'drop',
  'duplicate_activity',
  'duplicate_memo',
  'edit',
  'edit_homepage',
  'edit_link',
  'edit_schedule',
  'edit_time_period',
  'Elenco',
  'empty_cell',
  'empty_values',
  'empty_values2',
  'en',
  'encoding',
  'energy',
  'energy_monitoring',
  'equal',
  'esc_fullscreen',
  'Esporta',
  'euro',
  'euro2',
  'event',
  'event_sidenav',
  'exclamation',
  'expand',
  'exponential_function',
  'export',
  'facilities',
  'favorite',
  'features_selection',
  'file',
  'Filter_list',
  'filters',
  'finalized_labor',
  'fingerprint',
  'first_page',
  'Fixed_frequency_resampling',
  'flow',
  'flow2',
  'flow3',
  'flow4',
  'folder',
  'folder2',
  'Following',
  'frame',
  'frame_copy',
  'frame_copy2',
  'frequency',
  'full_star',
  'fullscreen',
  'funnel',
  'genealogy',
  'grafico 2',
  'graph',
  'grid',
  'hide',
  'history',
  'history_measure_empty',
  'home',
  'homepage',
  'hourglass',
  'hourglass_planner',
  'hyperparameter',
  'idea',
  'idea_1',
  'image',
  'import',
  'importance_variables',
  'Impostazioni',
  'improvement_action',
  'info',
  'info_1',
  'infomapping',
  'innest',
  'insert_parameter',
  'inspect',
  'integral_function',
  'internet_of_things',
  'istogram',
  'istogram2',
  'Istogram_dataset',
  'it',
  'lab',
  'label',
  'label_extension',
  'labelling',
  'last_page',
  'layer',
  'Layout',
  'layout_1',
  'layout_3',
  'layout_4',
  'Layout_6',
  'layout_free',
  'Like2',
  'link',
  'list',
  'little_star',
  'location',
  'lock',
  'logarithm',
  'logo_cmms',
  'logo_documenti',
  'logo_mipu',
  'logo_spareparts',
  'logo_ticket',
  'machinery',
  'mail',
  'maintenance',
  'map',
  'Mappatura_cluster',
  'marker_site',
  'maths',
  'matrix',
  'measure',
  'measure_profile_picture',
  'measure_ruler',
  'measures_meters',
  'memo',
  'Menu',
  'messages',
  'meter',
  'meter_profile_picture',
  'mindstore',
  'minus',
  'minus_circle',
  'Model_research',
  'modify_request',
  'multiply',
  'natural_logarithm',
  'no_data',
  'No_data_',
  'no_dataxp',
  'no_documents',
  'no_ticket',
  'Non_ci_sono_Documenti',
  'Non_ci_sono_elementi',
  'not_allowed',
  'not_selected',
  'note',
  'notify',
  'odl',
  'OdL_marker',
  'one_hot_encoding',
  'or',
  'other',
  'other_vert',
  'output_bar',
  'Output_profile_classification',
  'pareto',
  'pause',
  'pdr',
  'percentage',
  'performance',
  'performance_profile',
  'personalize',
  'Pie_chart',
  'pin',
  'pin_circle',
  'pipeline',
  'pipeline_columns',
  'pipeline_rows',
  'planner_filters',
  'play',
  'play_circle',
  'play_pause',
  'play_sqaure',
  'plus_minus',
  'pod',
  'polynomial_features',
  'pow_2',
  'power',
  'prediction_chart',
  'Predictions_comparison_steps',
  'prevision_explain_chart',
  'print',
  'product_icon',
  'publish',
  'qr_code',
  'quantity',
  'rebecca_logo',
  'rebecca_logo_white',
  'redisuals',
  'redo',
  'refresh',
  'remove',
  'report',
  'report2',
  'restore',
  'restore_dataset',
  'Risorsa',
  'Rolling_statistics',
  'rubber',
  'save',
  'save_pencil',
  'scaling',
  'scatterplot',
  'scatterplot_dataset',
  'scenari_icon',
  'scheduling_register',
  'scope',
  'score_separation',
  'search',
  'server',
  'set_up_frame',
  'settings',
  'share',
  'show',
  'signature',
  'sistema_icon',
  'site',
  'Site_icon',
  'Site_marker',
  'site_planner',
  'sites',
  'smart_labelling',
  'smartphone',
  'snow',
  'Spare_part_marker',
  'speaker',
  'square_root',
  'star',
  'storage',
  'suggest',
  'suggestion',
  'suggestion_sidenav',
  'sun',
  'suspended',
  'tab',
  'tag',
  'team',
  'team_leader',
  'team_planner',
  'template',
  'Template_report',
  'text',
  'threshold_calibration',
  'threshold_calibration',
  'thumb',
  'ticket',
  'Ticket_marker',
  'ticket_sidenav',
  'ticket_sidenav',
  'time',
  'time_enrichment',
  'Time_lag',
  'time_profiles',
  'time_restore',
  'timeline',
  'timer',
  'timeshift',
  'tips_updates',
  'topic',
  'touch',
  'trash',
  'tree',
  'tree_filter_selected',
  'trend_chart',
  'trend_chart2',
  'trend_time_data_chart',
  'turbine',
  'un_pin',
  'undo',
  'unlock',
  'unlock2',
  'unpublished',
  'us',
  'user',
  'user_placeholder',
  'user_preferences',
  'users',
  'visibility',
  'visibility_off',
  'wallet',
  'wallet2',
  'wallet3',
  'wallet_copy',
  'warehouse',
  'Warning_Diary',
  'watch',
  'waterfall',
  'wind',
  'wind_park',
  'wo_cart',
  'world',
  'zero_count',
  'remove_selection',
  'left_panel_close',
  'left_panel_open',
  'update_time',
  'add_column',
  'add_row',
  'number_format'
];

export const APP_ILLUSTRATIONS = [
  'Warning_Diary', 'Delivery_sidenav_Delivery_sidenav_black',
  'Delivery_sidenav_666666', 'card_carousel_loading',
  'Non_ci_sono_template', 'no_alarms', 'No_anomalies',
  'no_data_dataeXp', 'no_data_IoT', 'no_device', 'no_measure', 'no_meter',
  'no_results', 'No_results_iot', 'report_sidenav', 'models_not_found',
  'alarms_not_found', 'no_reports', 'group_sidenav', 'Heuristic_Algorithm',
  'product_sidenav', 'no_data_experience', 'EnPI_target', 'Task_sidenav',
  'trend_chart', 'area_chart', 'stacked_column_chart', 'grouped_column_chart',
  'table', 'pie_chart', 'donut_chart', 'treemap', 'sankey', 'radar_chart',
  'bubble_chart', 'bar_chart', 'scatterplot', 'pareto', 'kpi_indicator',
  'gauges', 'progress_bar', 'sunburst',
  'column_chart', 'stacked_areas_chart', 'alternate_row_table', 'no_messages',
  'simple_import', 'high_frequency_import', 'no_model_production', 'no_results_ai',
  'no_model_repository', 'frame', 'model_sidenav', 'frame_sidenav', 'Postprocessing',
  'Feedback', 'Alarms_settings', 'Simulazione', 'no_data_AI', 'error_buildingblock',
  'error_buildingblock_AI', 'no_elements', 'no_odl', 'Scene', 'optima_system',
  'Versions_history', 'no_infomaps', 'contatore', 'energy_vector', 'generic_group',
  'seu', 'autoML', 'no_data_am', 'LogoMIPU', 'odl_sidenav', 'pod_sidenav', 'pdr_sidenav',
  'project_sidenav', 'add_dataset_from_exist', 'add_dataset_from_meter', 'add_dataset_from_file',
  'spare_parts', 'transfers', 'movements', 'material_entrance', 'material_exit', 'purchase_request',
  'empty_movements', 'empty_purchase_request', 'Dataset', 'acquisition_filters', 'data_cleaning', 'modeling', 'history_ai', 'preprocessing',
  'add_column_illustration', 'labelling_illustration', 'time_enrichment_illustration', 'zero_count_illustration',
  'polynomial_features_illustration', 'smart_labelling_illustration', 'one_hot_encoding_illustration', 'binarizer_illustration',
  'discretization_illustration', 'encoding_illustration', 'label_extension_illustration',
  'dimensionality_reduction_illustration', 'features_selection_illustration', 'Rolling_statistics_illustration',
  'Fixed_frequency_resampling_illustration', 'Time_lag_illustration',
  'no_dataset', 'no_model', 'infomap_empty_state', 'no_data_energy_atlas', 'infomap_no_data', 'no_data_energy_atlas_2',
  'data_visualization_empty_state', 'Non ci sono documenti generati', 'default_site_home', 'default_site_am', 'default_site_ai', 'default_site_em', 'default_site_iiot',
  'default_site_machinery', 'default_asset_home', 'default_asset_am', 'default_asset_em', 'default_asset_ai', 'default_asset_iiot', 'default_asset_machinery',
  'default_transfer', 'default_exit', 'default_entry', 'default_rda', 'default_ticket', 'default_spare_part', 'default_WO', 'default_machinery_product', 'default_measure',
  'default_maintenance_plan', 'default_meter_iiot', 'default_meter_pod_pdr', 'default_edge_module', 'default_document', 'default_enpi', 'default_group_report', 'default_report'
];

export const toastrConfig = {
  positionClass: 'toast-bottom-left',
  newestOnTop: false,
  timeOut: 8000,
  toastClass: 'ngx-toastr is-toast',
  iconClasses: {
    error: 'is-toast is-toast-error',
    info: 'is-toast is-toast-info',
    success: 'is-toast is-toast-success',
    warning: 'is-toast is-toast-warning'
  }
};
